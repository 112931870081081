
body{
    min-height: 100vh;
    margin: 0 auto;
    padding: 0;
    background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;

}
*{
	font-family: "Montserrat";
	font-weight: 400;
}
a,a:hover{text-decoration: none;color: #fff;}
.no-padding{padding: 0px !important;}
.no-margin{margin: 0px !important;}
input:focus{
    outline: 0px !important;
    border-color: #000 !important;
    box-shadow:none !important;
}
input::placeholder{color: #95989A;}
.login_form_mx_width{
    max-width: 868px;
    margin: 0 auto;
}




.login_main_wrapper{
    width: 100%;
    height: 100vh;
}
.login_signup_form_main{
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 40px;
    box-shadow: 10px 10px 10px rgba(0,0,0,.2);
}
.login_signup_header{
    width: 100%;
    height: auto;
    padding: 60px 50px 60px 91px;
    background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
}
.login_signup_header h4{
    font-family: "MontserratBold";
    font-size: 25px;
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    margin: 0;
}
.login_signup_body{
    background: #fff;
    border-radius: 0px 0px 40px 40px;
    padding: 60px 150px 60px;
}
.login_signup_form label{
    margin: 0px;
    font-family: "MontserratBold";
    color: #000;
    font-size: 14px;
}
.login_signup_form input{
    font-size: 16px;
    border: 0px;
    border-bottom: 1px solid #95989A;
    border-radius: 0px;
    padding: 5px 0px;
    color: #000;
    height: 24px;
}
.login_signup_form .remember{
    text-align: left;
    font-family: "Montserrat" ;
    font-size:  14px ;
    letter-spacing: 0;
    color: #cccccc;
    padding-top: 3px;
}
.custom-radio .custom-control-label.remember::before {
    border: 1px solid #95989A;
    background: #fff;
     
}  
.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background: #5a8bb9 !important; 
    outline: none !important; 
} 
.custom-radio .custom-control-input:focus~.custom-control-label::before{ 
    outline: none !important;
    box-shadow: 0px 0px 3px 3px #a6c8ff !important;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after{
    background: none !important;
    outline: none !important;
}
.custom-control-input:focus~.custom-control-label::before{
    box-shadow: none !important;
}  
.custom-control.forgot_pass a:focus label{
    box-shadow: 0px 4px 3px -2px #a6c8ff !important; 
}
.forgot_pass{
    float: right;
}
.login_password_eye{
    position: relative;
}
.login_hidden_pass{
    position: absolute;
    top: -5px;
    right: 0;
    cursor: pointer;
}
.login_social_login{
    width: 100%;
    height: auto;
}
.login_social_login label{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
    font-family: "MontserratBold";
    color: #000;
    font-size: 14px;
}
.login_google_btn,.login_fb_btn{
    width: 100%;
    font-size: 20px;
    font-family: "MontserratBold";
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 10px 15px;
}

.login_fb_btn{
    background: #375D8F;
    color: #fff;
}
.login_theme_btn{
    width: 123px;
    height: 37px;
    background: #5A8BB9 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #5A8BB94D !important;
    border-radius: 50px;
    color: #fff;
    font-family: "MontserratBold";
    float: right;
    font-size: 18px;
    margin-top: 137px;
    border: 0px;
    transition: all .4s;
    cursor: pointer;
}
.login_theme_btn a{
    color: #fff;
    font-family: "MontserratBold";
}
.login_google_btn:hover,.login_fb_btn:hover,.login_google_btn:focus,.login_fb_btn:focus {
    box-shadow: 0px 7px 10px #929eaa;
}
.login_theme_btn:focus{
    border: 1px solid #30679A;
    outline: none !important;
}
.login_theme_btn:hover{
    background: #30679a !important;
}
.login_back{
    float: left;
    border: 1px solid #30679A;
    background: #fff;    
    box-shadow: none !important;
}
.login_back{
    color: #5A8BB9;
    font-family: "Montserrat";
}
.login_back:hover{color: #fff;font-family: "MontserratBold";}
.login_back:hover{box-shadow: 0px 5px 5px #5A8BB94D !important;}
.forgot_pass label{color: #30679A;}

/* Customize the label (the container) */
.login_container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .login_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .login_checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    border: 1px solid #95989A;
    background: #fff;
  }
  
  /* On mouse-over, add a grey background color */
  .login_container:hover input ~ .login_checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .login_container input:checked ~ .login_checkmark {
    background-color: #346a9c;
      border-radius: 50px;
  }
  
  /* Create the login_checkmark/indicator (hidden when not checked) */
  .login_checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the login_checkmark when checked */
  .login_container input:checked ~ .login_checkmark:after {
    display: block;
  }
  
  /* Style the login_checkmark/indicator */
  .login_container .login_checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
   
  /* mobile screen end */
  .login_select{position: relative;}
  
  .login_custom_select{
    font-size: 16px;
    border-radius: 0;
    border: none;
    background: transparent;
    width: 100%;
    overflow: hidden;
    padding: 0;
    height: auto;
    color: #000;
    border-bottom: 1px solid #95989A;
    appearance: none;
      -webkit-appearance:none
  }
  .login_custom_select:focus{
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid #30679A;
  }
  
  .login_custom_select optgroup {
    font-size: 16px;
    background: #fff;
    padding: 115px 0px;
  }
  
  .login_custom_caret:after {
      position: absolute;
      bottom: 18px;
      right: 10px;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #30679A;
      pointer-events: none;
  }
  .login_custom_select:focus .login_custom_caret:after{
    transform: rotate(180deg) !important;
    display: none !important;
  }

    .login_signup_form .form-group{
        margin-bottom: 0px;
        height: 65px;
    }









 /* wide screen end */
 @media (min-width:1921px) and (max-width:4000px){
     
    .login_form_mx_width {
        max-width: 540px; 
    }
    .login_theme_btn {margin: 70px 0 20px;}
    .login_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .login_signup_form label { font-size: 9px; }
    .login_signup_form input { 
        font-size: 12px !important;
        padding: 2px 0px 5px; 
        height: 20px;
    }
    .login_signup_form .remember {font-size: 9px; padding-top: 6px !important;}
    .login_google_btn, .login_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .login_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .login_main_wrapper { height: 100vh; }
    .login_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .login_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .login_google_btn img, .login_fb_btn img{
        width: 18px !important;
    }
    .login_hidden_pass{width: 15px;top: -8px;}
    .custom-control{margin-top: 5px !important;}


    /* old */
    .login_signup_form_main{margin: 30px 0px;}
    /* .login_main_wrapper{ height: auto;} */ 

    .login_container.remember span:nth-of-type(1) {
        padding-left: 10px;
    }

    .login_signup_form .remember {
        padding-top: 6px;
    }

    .forgot_pass {
        padding-top: 1px;
    }
     
  /* Create a custom checkbox */
  .login_checkmark {
    height: 20px;
    width: 20px;
  }
  .login_container .login_checkmark:after {
    left: 6.4px;
    top: 3px;
    width: 5.4px;
    height: 11px;
    }
}


 /* wide screen end */
 @media (min-width:992px) and (max-width:1920px){
    .login_form_mx_width {
        max-width: 540px; 
    }
    .login_theme_btn {margin: 70px 0 20px;}
    .login_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .login_signup_form label { font-size: 9px; }
    .login_signup_form input { 
        font-size: 12px !important;
        padding: 2px 0px 5px; 
        height: 20px;
    }
    .login_signup_form .remember {font-size: 9px; padding-top: 6px !important;}
    .login_google_btn, .login_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .login_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .login_main_wrapper { height: 100vh; }
    .login_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .login_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .login_google_btn img, .login_fb_btn img{
        width: 18px !important;
    }
    .login_hidden_pass{width: 15px;top: -8px;}
    .custom-control{margin-top: 5px !important;}


    /* old */
    .login_signup_form_main{margin: 30px 0px;}
    /* .login_main_wrapper{ height: auto;} */ 
}
/* wide screen end */
/* desktop screen */
@media (min-width:768px) and (max-width:991px){

    .login_form_mx_width {
        max-width: 540px; 
    }
    .login_theme_btn {margin: 70px 0 20px;}
    .login_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .login_signup_form label { font-size: 9px; }
    .login_signup_form input { 
         font-size: 12px !important;
        padding: 2px 0px 5px; 
        height: 20px;
    }
    .login_signup_form .remember {font-size: 9px; padding-top:6px !important;}
    .login_google_btn, .login_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .login_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .login_main_wrapper { height: 100vh; }
    .login_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .login_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .login_google_btn img, .login_fb_btn img{
        width: 18px !important;
    }
    .login_hidden_pass{width: 15px;top: -8px;}
    .custom-control{margin-top: 15px;}
 
    /* old */
    .login_signup_form_main{margin: 30px 0px;}
    /* .login_main_wrapper{ height: auto;} */
}
/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px){

     .login_form_mx_width {
        max-width: 540px; 
    }
    .login_theme_btn {margin: 70px 0 20px;}
    .login_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .login_signup_form label { font-size: 9px; }
    .login_signup_form input { 
         font-size: 12px !important;
        padding: 2px 0px 5px; 
        height: 20px;
    }
    .login_signup_form .remember {font-size: 9px; padding-top:6px !important;}
    .login_google_btn, .login_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .login_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .login_main_wrapper { height: 100vh; }
    .login_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .login_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .login_google_btn img, .login_fb_btn img{
        width: 18px !important;
    }
    .login_hidden_pass{width: 15px;top: -8px;}
    .custom-control{margin-top: 15px;} 
 
    /* old */
    /* .login_signup_header h4{font-size: 20px;}
    .login_signup_form_main{margin: 30px 0px;}
    .login_main_wrapper{ height: auto;} 
    .login_theme_btn{float: none;}
     .login_bottom_btn{
        width: 100%;
        height: auto;
        text-align: center;
        float: left;
    } */
}
/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px){


    .login_form_mx_width {
        max-width: 540px; 
    }
    .login_theme_btn {margin: 70px 0 20px;}
    .login_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .login_signup_form label { font-size: 9px; }
    .login_signup_form input { 
         font-size: 12px !important;
        padding: 2px 0px 5px; 
        height: 20px;
    }
    .login_signup_form .remember {font-size: 9px; padding-top:6px !important;}
    .login_google_btn, .login_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .login_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .login_main_wrapper { height: 100vh; }
    .login_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .login_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .login_google_btn img, .login_fb_btn img{
        width: 18px !important;
    }
    .login_hidden_pass{width: 15px;top: -8px;}
    .custom-control{margin-top: 15px;}
    .login_order-xs-2{order: 2;} 
    .login_order-xs-2 h4{text-align: center;}
   .login_order-xs-1{order: 1;text-align: center;}
   .login_order-xs-1 img{
       float: initial !important;
       margin-bottom: 10px;
   }
   .login_signup_header {padding: 32px 37px 32px 37px;}
 
    /* old */
    /* .login_signup_body,.login_signup_header{
        padding: 30px;
    }
    .login_signup_header h4{font-size: 16px;}
    .login_theme_btn{margin-top: 30px;}
    .login_order-xs-2{order: 2;} 
     .login_order-xs-2 h4{text-align: center;}
    .login_order-xs-1{order: 1;text-align: center;}
    .login_order-xs-1 img{
        float: initial !important;
        margin-bottom: 10px;
    }
    .login_signup_form_main{margin: 30px 0px;}
    .login_main_wrapper{ height: auto;}
    .login_theme_btn{float: none;}
    .login_bottom_btn{
        width: 100%;
        height: auto;
        text-align: center;
        float: left;
    } */
}
@media (min-width:0px) and (max-width:400px){
    .login_order-xs-2{order: 2;} 
    .login_order-xs-2 h4{text-align: center;}
   .login_order-xs-1{order: 1;text-align: center;}
   .login_order-xs-1 img{
       float: initial !important;
       margin-bottom: 10px;
   }
   .login_signup_header {padding: 32px 37px 32px 37px;}
   .login_signup_body {padding: 6px 40px;}
}
#remember_melogin{
    padding-top: 1px;
}
/* mobile screen end */