

body{
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

}
*{
font-family: "Montserrat";
font-weight: 400;
}
a,a:hover{text-decoration: none;color: #fff;}
.no-padding{padding: 0px !important;}
.no-margin{margin: 0px !important;}
input:focus{
  outline: 0px !important;
  border-color: #000 !important;
  box-shadow:none !important;
}
input::placeholder{color: #95989A;}
.rm_form_mx_width{
  max-width: 868px;
  margin: 0 auto;
}
.point-c{
  width: 28px;
}

.rm_main_wrapper{
  width: 100%;
  /* height: 100vh; */
}
.rm_signup_form_main{
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 40px;
  box-shadow: 10px 10px 10px rgba(0,0,0,.2);
}
.rm_signup_header{
  width: 100%;
  height: auto;
  padding: 60px 50px 60px 91px;
  background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px 40px 0px 0px;
  position: relative;
}
.rm_signup_header h4{
  font-family: "MontserratBold";
  font-size: 25px;
  text-align: left;
  letter-spacing: 0;
  color: #FFFFFF;
  margin: 0;
}
.rm_signup_body{
  background: #fff;
  border-radius: 0px 0px 40px 40px;
}
/* bottom btn */
.rm_body{
  text-align: center;
  margin: 50px 0px 0px 0px !important;
}
.rm_project_modals .rm_theme_btn{
  width: 100%;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  text-align: center;
  font-size: 16px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
}
.rm_project_modals .rm_theme_btn a{
  color: #fff;
  font-family: "MontserratBold";
}
.rm_project_modals .rm_theme_btn:focus{
  outline: none !important;
  border: 1px solid #30679A;
}
.rm_project_modals .rm_theme_btn:hover{
  background: #30679a !important;
}

.rm_project_modals .rm_modal_btn{
  float: left;
  border: 1px solid #30679A;
  background: #fff;    
  box-shadow: none !important;
}
.rm_project_modals .rm_modal_btn{
  color: #5A8BB9;
  font-family: "Montserrat";
}
.rm_project_modals .rm_modal_btn:hover{color: #fff;;box-shadow: 0px 5px 5px #5A8BB94D !important;}

.rm_project_modals{
  width: 100%;
  height: auto;
  border-bottom: 1px solid #95989A;
  padding: 6px 0px 0px;
}
.rm_project_modals ul{
  padding: 0px 50px;
  width: 100%;
  height: auto;
  margin: 0;
  text-align: center;
}
.rm_project_modals ul li{
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
.rm_modal_btn i{
  background: #5a8bb9;
  color: #fff;
  padding: 5px 7px;
  border-radius: 50px;
  font-size: 14px;
}
.rm_modal_btn:hover i{
  background: #fff;
  color: #5a8bb9;
}
.rm_theme_btn.rm_sub{
  width: 100%;
  padding: 0px 10px;
}
.rm_project_scroll{
  width: 100%;
  max-height: 308px;
  overflow-y: scroll;
  padding: 8px 75px;
  border-bottom: 1px solid #95989A;
}
.rm_project_scroll::-webkit-scrollbar-track
{
background-color: #EAF5FE;
}

.rm_project_scroll::-webkit-scrollbar
{
width: 12px;
background-color: #5A8BB9;
}
/* Buttons */
.rm_project_scroll::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}
/* Up */
.rm_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.rm_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}
/* Down */
.rm_project_scroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.rm_project_scroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.rm_project_scroll::-webkit-scrollbar-thumb
{
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}
.rm_project{
  text-align: left !important;
  padding: 0 !important;
  list-style: none;
}
.rm_project li{
  width: 100%;
  position: relative;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 5px 5px #D9D9D94D;
  border-radius: 20px;
  margin-bottom: 7px;
}
.rm_projects_list{
  width: 100%;
  height: auto;
  background: #fff;
  border-left: 14px solid #8EE952;
  border-radius: 20px;
  padding: 30px 0px;
  padding-left: 15px;
}
.rm_projects_list.rm_red{
  border-left: 14px solid #E95252 !important; 
}
.rm_projects_list i{
  background: #5A8BB9;
  color: #fff;
  padding: 5px 6px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
}
.rm_projects_list h4{
  font-size: 14px;
  font-family: "MontserratBold";
  margin: 0;
}
.rm_projects_list p{
  font-size: 12px;
  margin: 0;
}
.rm_signup_form label,.rm_signup_form label u{
  margin: 0px;
  font-family: "MontserratBold";
  color: #000;
  font-size: 14px;
}
.rm_signup_form input{
  font-size: 16px;
  border: 0px;
  border-bottom: 1px solid #95989A;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}
.rm_signup_form .rm_remember{
  text-align: left;
  font-family: "Montserrat" ;
  font-size:  16px ;
  letter-spacing: 0;
  color: #cccccc;
  padding:5px 0px;;
}
.rm_signup_form{
  padding: 8px 75px;
}
/* custom select */
.rm_select-box {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}
.rm_select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid #95989A;
}
.rm_select-box__current:focus{
  border-bottom: 2px solid #30679A;
}
.rm_select-box__current:focus + .rm_select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
          animation-name: none;
}
.rm_select-box__current:focus + .rm_select-box__list .rm_select-box__option {
  cursor: pointer;
}
.rm_select-box__current:focus .rm_select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.rm_select-box__icon {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 14px;
  transition: 0.2s ease;
}
.rm_select-box__value {
  display: flex;
}
.rm_select-box__input {
  display: none;
}
.rm_select-box__input:checked + .rm_select-box__input-text {
  display: block;
}
.rm_select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background-color: #fff;
}
.rm_select-box__list {
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
          animation-name: HideList;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
          animation-timing-function: step-start;
          box-shadow: 0px 3px 6px #00000029;
}
.rm_select-box__list li label{
  font-family: "Montserrat";
  font-size: 18px;
  border-bottom: 1px solid #eee;
}
.rm_select-box__option {
  display: block;
  padding: 20px;
  background-color: #fff;
}
.rm_select-box__option:hover, .rm_select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
/* modal css */
.rm_modal .modal-content{
border: 0px !important;
background: none !important;
}
.rm_modal_closed{
background: #5A8BB9;
  color: #e4f1fb;
  padding: 5px 8px 6px 7px;
  border-radius: 50px;
  position: absolute;
  top: 15px;
  right: 27px;
  cursor: pointer;
  z-index: 999;
}
.rm_modal .rm_signup_form{
padding: 60px 150px 60px;
position: relative;
}
/* bottom btn */
.rm_theme_btn{
width: 123px;
height: 37px;
background: #5A8BB9 0% 0% no-repeat padding-box;
box-shadow: 0px 5px 5px #5A8BB94D !important;
border-radius: 50px;
color: #fff;
font-family: "MontserratBold";
text-align: center;
font-size: 18px;
border: 0px;
transition: all .4s;
cursor: pointer;
margin-bottom: 20px;
}
.rm_theme_btn a{
color: #fff;
font-family: "MontserratBold";
}
.rm_google_btn:hover,.rm_fb_btn:hover,.rm_google_btn:focus,.rm_fb_btn:focus {
box-shadow: 0px 7px 10px #929eaa;
}
.rm_theme_btn:focus{
border: 1px solid #30679A;
outline: none !important;
}
.rm_theme_btn:hover{
background: #30679a !important;
}
.rm_back{
float: left;
border: 1px solid #30679A;
background: #fff;    
box-shadow: none !important;
}
.rm_back{
color: #5A8BB9;
font-family: "Montserrat";
}
.rm_back:hover{color: #fff;font-family: "MontserratBold";}
.rm_back:hover{box-shadow: 0px 5px 5px #5A8BB94D !important;}
.rm_paste_input{
height: 80px !important;
border: 1px solid #D9D9D9 !important;
padding: 0px 13px 30px 11px !important;
font-size: 14px !important;
}
.rm_paste_input:focus{
border: 1px solid #D9D9D9 !important;
}
.rm_paste_btn .input-group-text{
background: #F8F8F8 !important;
height: 80px !important;
width: 80px !important;
text-align: center !important;
justify-content: center !important;
cursor: pointer;
}
.rm_paste_input::placeholder{
padding: 0px;
color: #D9D9D9;
}
.rm_aai{
  position: absolute;
  right: 0px;
  top: 1px;
  z-index: 9;
}
.rm_aai i{
  background: #5A8BB9;
  color: #fff;
  border-radius: 50px;
  padding: 5.5px 7px;
  margin-right: 10px;
  cursor: pointer;
 font-size: 11px;
}
/* end */ 
.rm_select{position: relative;}

.rm_custom_select{
font-size: 16px;
border-radius: 0;
border: none;
background: transparent;
width: 100%;
overflow: hidden;
padding: 0;
height: auto;
color: #000;
border-bottom: 1px solid #95989A;
appearance: none;
-webkit-appearance:none
}
.rm_custom_select:focus{
outline: none;
box-shadow: none;
border-bottom: 2px solid #30679A;
}

.rm_custom_select optgroup {
font-size: 16px;
background: #fff;
padding: 115px 0px;
}

.rm_custom_caret:after {
position: absolute;
bottom: 18px;
right: 10px;
width: 0;
height: 0;
padding: 0;
content: '';
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: 6px solid #30679A;
pointer-events: none;
}
.rm_custom_select:focus .rm_custom_caret:after{
transform: rotate(180deg) !important;
display: none !important;
}

.text-right.rm_aai img:focus{
  box-shadow: 0px 0px 3px 3px #a6c8ff !important;
  outline: none !important;
}











/* wide screen end */
@media (min-width:992px) and (max-width:1900px){
  .rm_form_mx_width {
      max-width: 540px; 
  } 
  .rm_modal .rm_signup_form { padding: 37px 90px !important; }
  .rm_aai { top: 1px;right: 0px;}
  .rm_aai i{font-size: 7px; padding: 4.5px 5.5px;}
  .rm_body{margin: 0px !important;}  
  .rm_custom_caret:after { 
    bottom: 10px;
  }
  .rm_select .dropdown-heading-value, .dropdown-heading{
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  } 
  .rm_select .dropdown-heading{
    line-height: 10px !important;
  }
  .rm_custom_select,.rm_select .item-renderer input + span {
    font-size: 9px !important;
  }
  .rm_select .item-renderer input + span:before{
    top: 0px !important; 
    height: 1rem;
    width: 1rem;
}
.rm_select .item-renderer input:checked + span:after { 
    left: 6px;
    top: 4px;
    width: 3.5px;
    height: 7px; 
    border-width: 0 2px 2px 0; 
} 
  .rm_form_upload_message p{
    height: 50px !important; 
    font-size: 9px !important;
    height: 24px !important;
  }
  .rm_paste_btn .input-group-text{
       height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }
  .rm_theme_btn {margin: 40px 0 0px;}
  .rm_back{margin: 5px 0px;}
  .rm_signup_header h4 {font-size: 14px; }
  img[alt="Logo"] {  width: 60px !important; }
  .rm_signup_form label,.rm_signup_form label, .rm_signup_form label u{ font-size: 9px; }
  .rm_signup_form input { font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;}
  .rm_signup_form .remember {font-size: 12px; }
  .rm_google_btn, .rm_fb_btn { 
      font-size: 14px;
      padding: 6px 17px;
  }
  .rm_theme_btn {
      width: 70px;
    height: 24px;
    font-size: 11px;
  } 
  .al_paste_btn .input-group-text { 
    height: 60px !important;
    width: 60px !important;}
   .al_signup_header { 
       padding: 32px 37px 32px 55px;
       border-radius: 20px 20px 0px 0px;
   }

  .rm_signup_header { 
      padding: 32px 37px 32px 55px;
      border-radius: 20px 20px 0px 0px;
  }
  .rm_signup_body { 
      border-radius: 0px 0px 20px 20px; 
  }
  .rm_google_btn img, .rm_fb_btn img{
      width: 20px !important;
  }
  .rm_hidden_pass{width: 15px;}
  .rm_signup_form .form-group{
      margin-bottom: 10px;
  }
  .custom-control{margin-top: 15px;}
  .rm_signup_form_main{margin: 30px 0px;} 
}
/* wide screen end */
/* desktop screen */
@media (min-width:768px) and (max-width:991px){

  .rm_form_mx_width {
      max-width: 540px; 
  }
  .rm_modal .rm_signup_form { padding: 37px 90px !important; }
  .rm_aai { top: 1px;right: 0px;}
  .rm_aai i{font-size: 7px; padding: 4.5px 5.5px;}
  .rm_body{margin: 0px !important;} 
  .rm_custom_caret:after { 
    bottom: 10px;
  }
  .rm_select .dropdown-heading-value, .dropdown-heading{
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  } 
  .rm_select .dropdown-heading{
    line-height: 10px !important;
  }
  .rm_custom_select,.rm_select .item-renderer input + span {
    font-size: 9px !important;
  }
  .rm_select .item-renderer input + span:before{
    top: 0px !important; 
    height: 1rem;
    width: 1rem;
}
.rm_select .item-renderer input:checked + span:after { 
    left: 6px;
    top: 4px;
    width: 3.5px;
    height: 7px; 
    border-width: 0 2px 2px 0; 
} 
  .rm_form_upload_message p{
    height: 50px !important; 
    font-size: 9px !important;
    height: 24px !important;
  }
  .rm_paste_btn .input-group-text{
       height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }
  .rm_theme_btn {margin: 40px 0 0px;}
  .rm_back{margin: 5px 0px;}
  .rm_signup_header h4 {font-size: 14px; }
  img[alt="Logo"] {  width: 60px !important; }
  .rm_signup_form label,.rm_signup_form label, .rm_signup_form label u{ font-size: 9px; }
  .rm_signup_form input { font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;}
  .rm_signup_form .remember {font-size: 12px; }
  .rm_google_btn, .rm_fb_btn { 
      font-size: 14px;
      padding: 6px 17px;
  }
  .rm_theme_btn {
      width: 70px;
    height: 24px;
    font-size: 11px;
  } 
  .al_paste_btn .input-group-text { 
    height: 60px !important;
    width: 60px !important;}
   .al_signup_header { 
       padding: 32px 37px 32px 55px;
       border-radius: 20px 20px 0px 0px;
   }
  .rm_signup_header { 
      padding: 32px 37px 32px 55px;
      border-radius: 20px 20px 0px 0px;
  }
  .rm_signup_body { 
      border-radius: 0px 0px 20px 20px; 
  }
  .rm_google_btn img, .rm_fb_btn img{
      width: 18px !important;
  }
  .rm_hidden_pass{width: 15px;}
  .rm_signup_form .form-group{
      margin-bottom: 10px;
  }
  .custom-control{margin-top: 15px;}
 
  .rm_signup_form_main{margin: 30px 0px;} 
}
/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px){

   .rm_form_mx_width {
      max-width: 540px; 
  }
  .rm_modal .rm_signup_form { padding: 37px 90px !important; }
  .rm_aai { top: 1px;right: 0px;}
  .rm_aai i{font-size: 7px; padding: 4.5px 5.5px;}
  .rm_body{margin: 0px !important;} 
  .rm_custom_caret:after { 
    bottom: 10px;
  }
  .rm_select .dropdown-heading-value, .dropdown-heading{
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  } 
  .rm_select .dropdown-heading{
    line-height: 10px !important;
  }
  .rm_custom_select,.rm_select .item-renderer input + span {
    font-size: 9px !important;
  }
  .rm_select .item-renderer input + span:before{
    top: 0px !important; 
    height: 1rem;
    width: 1rem;
}
.rm_select .item-renderer input:checked + span:after { 
    left: 6px;
    top: 4px;
    width: 3.5px;
    height: 7px; 
    border-width: 0 2px 2px 0; 
} 
  .rm_form_upload_message p{
    height: 50px !important; 
    font-size: 9px !important;
    height: 24px !important;
  }
  .rm_paste_btn .input-group-text{
       height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }
  .rm_theme_btn {margin: 40px 0 0px;}
  .rm_back{margin: 5px 0px;}
  .rm_signup_header h4 {font-size: 14px; }
  img[alt="Logo"] {  width: 60px !important; }
  .rm_signup_form label,.rm_signup_form label, .rm_signup_form label u{ font-size: 9px; }
  .rm_signup_form input { font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;}
  .rm_signup_form .remember {font-size: 12px; }
  .rm_google_btn, .rm_fb_btn { 
      font-size: 14px;
      padding: 6px 17px;
  }
  .rm_theme_btn {
      width: 70px;
    height: 24px;
    font-size: 11px;
  }  
  .al_paste_btn .input-group-text { 
    height: 60px !important;
    width: 60px !important;}
   .al_signup_header { 
       padding: 32px 37px 32px 55px;
       border-radius: 20px 20px 0px 0px;
   }
  .rm_signup_header { 
      padding: 32px 37px 32px 55px;
      border-radius: 20px 20px 0px 0px;
  }
  .rm_signup_body { 
      border-radius: 0px 0px 20px 20px; 
  }
  .rm_google_btn img, .rm_fb_btn img{
      width: 18px !important;
  }
  .rm_hidden_pass{width: 15px;}
  .rm_signup_form .form-group{
      margin-bottom: 10px;
  }
  .custom-control{margin-top: 15px;}  
}
/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px){


  .rm_form_mx_width {
      max-width: 540px; 
  }
  .rm_aai { 
    right: 42px;
  }
  .rm_modal .rm_signup_form { padding: 37px 50px !important; }
  .rm_aai { top: 1px;right: 0px;}
  .rm_aai i{font-size: 7px; padding: 4.5px 5.5px;}
  .rm_body{margin: 0px !important;} 
  .rm_custom_caret:after { 
    bottom: 10px;
  }
  .rm_select .dropdown-heading-value, .dropdown-heading{
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  } 
  .rm_select .dropdown-heading{
    line-height: 10px !important;
  }
  .rm_custom_select,.rm_select .item-renderer input + span {
    font-size: 9px !important;
  }
  .rm_select .item-renderer input + span:before{
    top: 0px !important; 
    height: 1rem;
    width: 1rem;
}
.rm_select .item-renderer input:checked + span:after { 
    left: 6px;
    top: 4px;
    width: 3.5px;
    height: 7px; 
    border-width: 0 2px 2px 0; 
} 
  .rm_form_upload_message p{
    height: 50px !important; 
    font-size: 9px !important;
        height: 24px !important;
  }
  .rm_paste_btn .input-group-text{
       height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }
  .rm_theme_btn {margin: 40px 0 0px;}
  .rm_back{margin: 5px 0px;}
  .rm_signup_header h4 {font-size: 14px; }
  img[alt="Logo"] {  width: 60px !important; }
  .rm_signup_form label,.rm_signup_form label, .rm_signup_form label u{ font-size: 9px; }
  .rm_signup_form input { font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;}
  .rm_signup_form .remember {font-size: 12px; }
  .rm_google_btn, .rm_fb_btn { 
      font-size: 14px;
      padding: 6px 17px;
  }
  .rm_theme_btn {
      width: 70px;
    height: 24px;
    font-size: 11px;
  } 
  .al_paste_btn .input-group-text { 
    height: 60px !important;
    width: 60px !important;}
   .al_signup_header { 
       padding: 32px 37px 32px 55px;
       border-radius: 20px 20px 0px 0px;
   }
  .rm_signup_header { 
      padding: 32px 37px 32px 55px;
      border-radius: 20px 20px 0px 0px;
  }
  .rm_signup_body { 
      border-radius: 0px 0px 20px 20px; 
  }
  .rm_google_btn img, .rm_fb_btn img{
      width: 18px !important;
  }
  .rm_hidden_pass{width: 15px;}
  .rm_signup_form .form-group{
      margin-bottom: 10px;
  }
  .custom-control{margin-top: 15px;}
  .rm_order-xs-2{order: 2;} 
  .rm_order-xs-2 h4{text-align: center;}
 .rm_order-xs-1{order: 1;text-align: center;}
 .rm_order-xs-1 img{
     float: initial !important;
     margin-bottom: 10px;
 }
 .rm_signup_header {padding: 32px 37px 32px 37px;}
 
}
@media (min-width:0px) and (max-width:400px){
  .rm_order-xs-2{order: 2;} 
  .rm_order-xs-2 h4{text-align: center;}
 .rm_order-xs-1{order: 1;text-align: center;}
 .rm_order-xs-1 img{
     float: initial !important;
     margin-bottom: 10px;
 }
 .rm_signup_header {padding: 32px 37px 32px 37px;}
 .rm_signup_body { padding:0px !important;}
 .rm_modal .rm_signup_form { padding: 37px 30px !important; }
 .rm_aai { 
  right: 24px;
}
}
/* mobile screen end */
